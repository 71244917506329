import { Component, OnInit, Input } from '@angular/core'

import { LayoutService, AnalyticsService } from '../../../@core/utils'

import { NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme'

import { environment } from '../../../../environments/environment'
import { CustomAuthService } from '../../../shared/services/custom-auth.service'
import { Router } from '@angular/router'

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  enviromnentName: string

  @Input() position = 'normal'

  user: { username: string }

  userMenu = [{ title: 'Log out' }]

  userPictureOnly: boolean = false

  themes = [
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'default',
      name: 'Corporate',
    },
  ]

  currentTheme = 'default'

  constructor(
    private router: Router,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private analyticsService: AnalyticsService,
    private authService: CustomAuthService
  ) {}

  ngOnInit() {
    const myRawToken = this.authService.payload()
    if (myRawToken !== null) {
      const username = myRawToken['username']

      this.user = { username: username }
    }
    this.enviromnentName = `(${environment.stage})`
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName)
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar')
    this.layoutService.changeLayoutSize()

    return false
  }

  goToHome() {
    this.menuService.navigateHome()
  }

  navigateHome(url: string): void {
    this.router.navigateByUrl(url), this.menuService.navigateHome()
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch')
  }

  doLogOut() {
    localStorage.clear()
  }
}
