export const environment = {
  production: false,
  stage: "PROD",
  version: "2.001",
  urlBasePath: "https://console.ipsense.cloud",
  projetoId: "a9eddd30-cd6a-6f5b-1f63-d05a304d654d",
  tipoAutenticacao: "ipsense",
  cognito: {
    // identityPoolId: "",
    poolId: "us-east-1_IEsIgwdyp",
    clientId: "66lcp2ilukl04ghh3h4sguur4s",
    appDomain: "auth.console.ipsense.cloud",
    region: "us-east-1",
    // Paranoia: 7,
    responseConfig: {
      responseType: "token",
      scope: "openid",
    },
    recaptchaSiteKey: "6LdHPLseAAAAAHHKDxGDQ4EAx351oUuIYBNXZbCd"
  },
  APIs: {
    IpsenseForms: {
      BasePath: "https://api.console.ipsense.cloud",
      Projeto: "project",
      ContentType: "content-type",
      Documents: "ecm/documents",
      ContenTypeInstance: "content-type-instance",
      Menu: "web-console/menu",
      ContentEngine: "content",
      // "api-id": "",
      // apiKey: "",
      auth: "auth/login",
      LogEvent: "ecm/events/list",
    },
    Iccm: {
      BasePath: "https://api.console.ipsense.cloud",
      Repositorio: "repositorio",
      Menu: "web-console/menu",
      LogEvent: "ecm/events/list",
      Documents: "ecm/documents",
      // "api-id": "",
      // apiKey: "",
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
