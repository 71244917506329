import { Injectable, Injector } from '@angular/core'

import { Observable } from 'rxjs'
import { map, catchError } from 'rxjs/operators'

import { BaseResourceService } from './base-resource.service'
import { MenuSideBarModel } from '../models/menu-sidebar.model'
import { environment } from '../../../environments/environment'

const env = environment.APIs.IpsenseForms
export const servicePath = `${env.BasePath}/${env.Menu}`

@Injectable()
export class MenuSidebarService extends BaseResourceService<MenuSideBarModel> {
  constructor(protected injector: Injector) {
    super(servicePath, injector, MenuSideBarModel.fromJson)
  }

  getById(id: string): Observable<MenuSideBarModel> {
    const url = `${this.apiPath}?project_id=${id}`

    console.log('Requesting', url)

    return this.http.get(url)
  }
}
