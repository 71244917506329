import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OAuth2LoginComponent } from './oauth2-login/oauth2-login.component';
import { OAuth2CallbackComponent } from './oauth2-callback/oauth2-callback.component';

const routes: Routes = [{
  path : '',
  component: OAuth2LoginComponent,
},
{
  path: 'login',
  component: OAuth2LoginComponent,
},
{
  path: 'callback',
  component: OAuth2CallbackComponent,
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthCognitoRoutingModule { }