import { APP_BASE_HREF } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from "@angular/core";
import localept from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localept, "pt");
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";

import {
  // NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbWindowModule,
  NbToastrModule,
} from "@nebular/theme";

import { CoreModule } from "./@core/core.module";
import { ThemeModule } from "./@theme/theme.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthCognitoModule } from "./auth-cognito/auth-cognito.module";
import { AuthGuard } from "./shared/services/auth-guard.service";
import {
  MaterialDesignFrameworkModule,
  Bootstrap4FrameworkModule,
} from "angular6-json-schema-form";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { RecaptchaModule } from "ng-recaptcha";

import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthCognitoModule,
    MaterialDesignFrameworkModule,
    Bootstrap4FrameworkModule,
    NgbModule,
    ReactiveFormsModule,
    MatSelectModule,

    ThemeModule.forRoot(),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    // NbChatModule.forRoot({
    //   messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    // }),
    CoreModule.forRoot(),
    ToastrModule.forRoot(),
    NbToastrModule.forRoot(),
    RecaptchaModule.forRoot(),
  ],
  exports: [RecaptchaModule],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    AuthGuard,
    { provide: LOCALE_ID, useValue: "pt" },
  ],
})
export class AppModule {}
