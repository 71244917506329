import { NbAuthOAuth2Token } from '@nebular/auth';
// Create new token for Cognito auth so it returns id_token instead of access_token
export class NbAuthCognitoToken extends NbAuthOAuth2Token {

  // let's rename it to exclude name clashes
  static NAME = 'nb:auth:cognito:token';

  getValue(): string {
    return this.token.id_token;
  }
}
