import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import moment = require('moment');
@Injectable({
  providedIn: 'root',
})
export class CustomAuthService {

  constructor() { }

  isAuthenticated(): Observable<boolean> {
    return from(new Observable<boolean>(observer => observer.next(this.isExpired())));
  }

  payload(): any {
    try {
      const token = sessionStorage.getItem('auth_token');
      return jwt_decode(token);
    } catch (error) {
      return null;
    }

  }

  getValue() {
    return sessionStorage.getItem('auth_token');
  }

   isExpired() {
    const token = this.payload();

    if (token === null || typeof (token.exp) === 'undefined') {
      return false;
    }

    return moment(token.exp * 1000).isSameOrAfter(moment.now());
  }
}
