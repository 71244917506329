import { environment } from '../../../environments/environment';
import { Guid } from 'guid-typescript';
export class CognitoConfigUtils {

  static envData = environment.cognito;
  private static ICCM_USER_SECRET = 'userscretlogin';

  static getUserSecret(): string | null {
    return localStorage.getItem(this.ICCM_USER_SECRET);
  }
  static SetUserSecret(): string | void {
    const userSecret = Guid.create().toString();
    localStorage.setItem(this.ICCM_USER_SECRET, userSecret);
    return userSecret;
  }

  static encodedRedirectUri() {
    return encodeURIComponent(`${environment.urlBasePath}/auth/callback`);
  }

  static getEndPointLogin(): string {
    this.SetUserSecret();
    let fullUrl = `https://${this.envData.appDomain}/login?redirect_uri=${this.encodedRedirectUri()}&state=STATE`;

    //const fullUrl = `https://${this.envData.appDomain}/login`;
    return fullUrl;
  }

  static getEndPointLogout(): string {
    const cognito = environment.cognito;
    const urlBaseEnconded = encodeURI(`${environment.urlBasePath}/auth`);

    let fullUrl = `https://${cognito.appDomain}/logout?logout_uri=${urlBaseEnconded}`;
    fullUrl += `&client_id=${cognito.clientId}`;
    return fullUrl;
  }

}
