import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="text-center w-100 footer-container">
    v {{ version }} - Copyright 2018-2021 -
      <a href="https://www.ipsense.com.br/" target="_blank">IPsense Tecnologia</a>
        - 2021 / ®All rights reserved
    </span>
  `,
})
export class FooterComponent implements OnInit {

  version: string;
  ngOnInit(): void {
    this.version = environment.version;
  }

 }
