import { Component, OnDestroy, OnInit } from '@angular/core'
import { NbAuthOAuth2Token, NbAuthResult, NbAuthService } from '@nebular/auth'
import { takeWhile } from 'rxjs/operators'
import { LoginTools } from '../../@core/utils/login.tools'
import { environment } from '../../../environments/environment'
import { HttpErrorResponse } from '@angular/common/http'
import { Router } from '@angular/router'
import { FormBuilder, Validators, FormGroup } from '@angular/forms'

declare var VANTA

export interface UserModelLogin {
  username?: string
  password?: string
  captchaToken?: string
}
@Component({
  selector: 'ngx-oauth2-login.component',
  templateUrl: './oauth2-login.component.html',
  styleUrls: ['./oauth2-login.component.scss'],
})
export class OAuth2LoginComponent implements OnInit, OnDestroy {
  imgBackgroundKey: number
  imgBackgroundImageStyle: string
  token: NbAuthOAuth2Token
  cognitoUrl: string
  user: UserModelLogin = {}
  submitted: boolean
  errors: Array<any> = []
  loginForm: FormGroup
  serverErrorAlert: boolean
  version: string = ''
  alive = true

  constructor(
    private authService: NbAuthService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.authService
      .onTokenChange()
      .pipe(takeWhile(() => this.alive))
      .subscribe((token: NbAuthOAuth2Token) => {
        this.token = null
        if (token && token.isValid()) {
          this.token = token
        }
      })
  }

  ngOnInit() {
    VANTA.WAVES({
      el: '#vanta-background',
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x595959,
      shininess: 42.0,
      waveHeight: 5.0,
      waveSpeed: 0.6,
      zoom: 0.71,
    })

    this.buildLoginForm()

    this.cognitoUrl = LoginTools.buildRedirectUrl()

    if (environment.tipoAutenticacao === 'cognito') {
      console.log('Redirecionando para', this.cognitoUrl)
      window.location.href = this.cognitoUrl
    }
    this.setRandomBackground()
    this.version = environment.version
  }

  private setRandomBackground() {
    const lastKey = localStorage.getItem('lastKey') || 1

    let key = Math.floor(Math.random() * 4) + 1

    if (key === lastKey) {
      key = Math.floor(Math.random() * 4) + 1
    }

    this.imgBackgroundImageStyle = `url("/assets/images/login/0${key}.png")`

    localStorage.lastKey = key.toString()
  }

  login() {
    this.submitted = true
    if (this.loginForm.valid) {
      const user: UserModelLogin = Object.assign({}, this.loginForm.value)
      this.authService
        .authenticate('keycloack', user)
        .subscribe((nbAuthResult: NbAuthResult) => {
          if (nbAuthResult.isSuccess()) {
            sessionStorage.setItem(
              'auth_token',
              nbAuthResult.getToken().getValue()
            )
            localStorage.clear()
            this.serverErrorAlert = false
            window.location.href = nbAuthResult.getRedirect()
          } else {
            this.serverErrorAlert = true
            this.handleError(nbAuthResult)
          }
          this.submitted = false
        })
    }
  }

  logout() {
    localStorage.clear()

    this.router.navigate(['auth', 'login'])

    /*  this.authService.logout('keycloack')
       .pipe(takeWhile(() => this.alive))
       .subscribe((authResult: NbAuthResult) => {
         if (authResult.isSuccess()) {
           return this.router.navigateByUrl(authResult.getRedirect());
         }
       }); */
  }
  onServerErrorAlertClose() {
    this.serverErrorAlert = false
  }

  protected buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(2)]],
      password: ['', [Validators.required, Validators.minLength(1)]],
      captchaToken: ['', [Validators.required]],
    })
  }

  private handleError(responseResult: NbAuthResult) {
    const errors: Array<string> = []
    const response = responseResult.getResponse()
    const msgPadrao =
      'Erro ao processar a requisição. Por favor, tente novamente em alguns minutos.'

    if (response instanceof HttpErrorResponse) {
      errors.push(response.error.errorMessage || msgPadrao)
    } else {
      errors.concat(responseResult.getErrors())
    }
    this.errors = errors
  }

  ngOnDestroy(): void {
    this.alive = false
  }
}
