import { environment } from '../../../environments/environment';
import { CognitoConfigUtils } from './cognito.conf';

export class LoginTools {
  public static buildRedirectUrl() {

    let url = `https://${environment.cognito.appDomain}/login?`;
    url += `&response_type=code`;
    url += `&client_id=${environment.cognito.clientId}`;
    url += `&redirect_uri=${CognitoConfigUtils.encodedRedirectUri()}`;
    url += `&scope=${environment.cognito.responseConfig.scope}`;

    return url;

  }
}
