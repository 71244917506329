import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


// https://angular.io/guide/http#intercepting-requests-and-responses


export class ApiHttpInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // clone request (Principle of immutability)

    const loginUrl = `${environment.APIs.IpsenseForms.BasePath}/${environment.APIs.IpsenseForms.auth}`;


    if (request.url !== loginUrl) {
      const auth_app_token: any = {};
      auth_app_token.value = sessionStorage.auth_token;
      const bearerToken: { 'access_token': {} } = { access_token: auth_app_token.value };

      if (typeof auth_app_token.value === 'object') {
        bearerToken.access_token = JSON.parse(auth_app_token.value);
      }
      let cloneRequest = request.clone({
        headers:
          request.headers.set('Content-Type', 'application/json'),
      });

      if (!request.headers.has('Accept')) {
        cloneRequest = cloneRequest.clone({ headers: request.headers.set('Accept', 'application/json') });
      }
      cloneRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${bearerToken.access_token}`),
      });

      return next.handle(cloneRequest);

    } else {

      let cloneRequest = request.clone({
        headers:
          request.headers.set('Content-Type', 'application/json'),
      });

      cloneRequest = request.clone({
        body: Object.assign({ responseType: 'object' }, cloneRequest.body),
      });

      if (!request.headers.has('Accept')) {
        cloneRequest = cloneRequest.clone({ headers: request.headers.set('Accept', 'application/json') });
      }

      return next.handle(cloneRequest);
    }

  }
}

