import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from '../../environments/environment';

import { AuthCognitoRoutingModule } from './auth-cognito-routing.module';
import { OAuth2LoginComponent } from './oauth2-login/oauth2-login.component';
import { OAuth2CallbackComponent } from './oauth2-callback/oauth2-callback.component';


import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule, NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbLayoutModule,
  NbAlertModule,
} from '@nebular/theme';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaFormsModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';

@NgModule({
  declarations: [OAuth2LoginComponent, OAuth2CallbackComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NbAlertModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbLayoutModule,
    AuthCognitoRoutingModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.cognito.recaptchaSiteKey,
      },
    }, {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'pt',
    },
  ],
})
export class AuthCognitoModule { }
