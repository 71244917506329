import { Injectable, Inject } from '@angular/core';

import {
  NbOAuth2AuthStrategy, NbOAuth2AuthStrategyOptions, NbAuthStrategyClass,
  NbOAuth2ResponseType,
} from '@nebular/auth';

import { CognitoConfigUtils } from '../utils/cognito.conf';

import { map } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';

import { NB_WINDOW } from '@nebular/theme';

@Injectable()
export class NbCognitoADB2CAuthStrategy extends NbOAuth2AuthStrategy {

  // we need this methos for strategy setup
  static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [NbCognitoADB2CAuthStrategy, options];
  }

  protected buildRedirectUrl() {
    let redirect = super.buildRedirectUrl();
    const urlParams = new URLSearchParams(redirect);

    if (!urlParams.has('redirect_uri')) {

      redirect = `${redirect}&redirect_uri=${CognitoConfigUtils.encodedRedirectUri()}`;
    }

    return redirect;
  }

  protected redirectResults: any = {
    [NbOAuth2ResponseType.CODE]: () => observableOf(null),

    [NbOAuth2ResponseType.TOKEN]: () => {
      return observableOf(this.activatedRoute.snapshot.fragment).pipe(
        map(fragment => this.parseHashAsQueryParams(fragment)),
        map((params: any) => !!(params && (params.id_token || params.error))),
      );
    },
  };

  constructor(http: HttpClient,
    private activatedRoute: ActivatedRoute,
    @Inject(NB_WINDOW) window: any) {
    super(http, activatedRoute, window);
  }
}
